import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';
import 'react-toastify/dist/ReactToastify.css';
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));


// Pages
const Login = React.lazy(() => import('./views/Usuarios/Login'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const user = localStorage.getItem('usuarioensesion');

var userparse =""

if (typeof user === 'undefined'){

}else{
userparse=JSON.parse(user);
}
//console.log("userparse" + userparse["NOMBRE"])
class App extends Component {
 //  <Route path="/" name="Login Page" render={props => <Login {...props}/>} />
 //{userparse && <Route path="/" name="Home" render={props => <TheLayout {...props}/>} />}    <Route path="/" name="Home" render={props => <TheLayout {...props}/>}/>
  render() {
    return (

      <HashRouter>
          <React.Suspense fallback={loading}>
            <Switch >

            {  userparse  ? <Route path="/" name="Home" render={props => <TheLayout {...props}/>} />:
            <Route path="/" name="Login Page" render={props => <Login {...props}/>} />  }

            </Switch>
          </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
